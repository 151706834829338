<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <!-- src="https://sf-cdn.coze.com/obj/unpkg-va/flow-platform/chat-app-sdk/0.1.0-beta.4/libs/oversea/index.js" -->
      <!-- 王：营养师ai助理的 -->
      <!-- <remote-js
        v-if="$store.getters.userInfo !== undefined && $store.getters.userInfo.user !== undefined && $store.getters.userInfo.user.id !== undefined"
        src="https://lf-cdn.coze.cn/obj/unpkg/flow-platform/chat-app-sdk/0.1.0-beta.5/libs/cn/index.js"
        @load="jsLoaded"
        @error="jsLoadError"
      /> -->
      <!-- 我的测试ai -->
      <!-- <remote-js
        v-if="$store.getters.userInfo !== undefined && $store.getters.userInfo.user !== undefined && $store.getters.userInfo.user.id !== undefined"
        src="https://lf-cdn.coze.cn/obj/unpkg/flow-platform/chat-app-sdk/0.1.0-beta.5/libs/cn/index.js"
        @load="jsLoaded"
        @error="jsLoadError"
      /> -->
      <!-- <component :is="layout"> -->
      <router-view />
      <!-- </component> -->
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import VueCookies from 'vue-cookies'

moment.locale('zh-cn')
export default ({
  data () {
    return {
      zh_CN,
    }
  },
  computed: {
    // Sets components name based on current route's specified layout, defaults to
    // <layout-default></layout-default> component.
    // layout() {
    // 	return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
    // }
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods: {
    beforeunloadHandler (e) {
      // 删除cookie记录的上次浏览的路由地址。避免关闭页面重新打开的时候进非菜单页面时没有参数造成的错误
      VueCookies.remove('AJ-DMS-NOW-URL')
      // 写入浏览器缓存，避免刷新时字典数据没有在页面加载完之前回来
      sessionStorage.setItem('dms.dictionariesList', JSON.stringify(this.$store.getters.dictionariesList))
    },
    jsLoaded () {
      CozeWebSDK = window.CozeWebSDK
      new CozeWebSDK.WebChatClient({
        config: {
          // bot_id: '7362818789006295058',
          // 王
          // bot_id: '7395903881597419539',
          // 我的测试
          bot_id: '7413271537736450048',
        },
        componentProps: {
          // 王
          // title: '膳食家AI营养师',
          // icon: 'https://health.renyaoxing.com/logo_ryx.png',
          // lang: 'zh-CN'
          // 我的测试
          title: '我自己的测试',
          lang: 'zh-CN'
        },
      })
    },
    jsLoadError () {
      // 加载失败时的操作
    }
  }
})

</script>

<style lang="scss">
</style>