import request from '@/utils/request'

const apiUrls = {
  getProductPackageSelectList: '/HealthProductPackages/getProductPackageSelectList'
}

export function getProductPackageSelectList (parameter) {
  return request({
    url: apiUrls.getProductPackageSelectList,
    method: 'get',
    params: parameter
  })
}
