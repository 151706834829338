<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        :bodyStyle="{'padding-top': '10px'}"
        v-if="patientInfo.id !== undefined"
      >
        <!-- 客户信息 -->
        <div class="font-bold content-color">
          <a-button
            class="float-right"
            icon="search"
            size="small"
            @click="clickShowManage"
            type="primary"
          >客户档案信息</a-button>
          <div class="my-5">
            <span class="label-color ml-10">客户姓名：</span>
            {{ patientInfo.name }}，
            <span class="label-color ml-10">性别：</span>
            {{ 'ryx.gender' | showText(patientInfo.gender.toString()) }}，
            <span class="label-color ml-10">出生日期：</span>
            {{ patientInfo.birth_date === null || patientInfo.birth_date === '' ? '-' : patientInfo.birth_date }}，
            <span class="label-color ml-10">客户编号：</span>
            {{ patientInfo.patient_no === null || patientInfo.patient_no === '' ? '-' : patientInfo.patient_no }}，
            <span class="label-color ml-10">联系电话：</span>
            {{ patientInfo.contact_phone === null || patientInfo.contact_phone === '' ? '-' : patientInfo.contact_phone }}
          </div>
          <div>
            <span class="label-color ml-10">关注问题：</span>
            <a-tag
              color="orange"
              v-for="tag in patientInfo.his_patient_tag"
              :key="tag.tag_id"
            >
              {{tag.tag_text}}
            </a-tag>
          </div>
        </div>

        <a-divider
          dashed
          style="margin-bottom: 5px;"
        />
        <a-tabs
          :activeKey="activeTabKey"
          @change="(v)=>tabChange(0,v)"
          id="formRow"
        >
          <a-tab-pane
            key="1"
            forceRender
          >
            <span slot="tab">
              <a-badge :offset="[5,0]">
                <span
                  slot="count"
                  class="must-mark"
                >*</span>1、客户主诉</a-badge>
            </span>
            <a-form
              :form="appointForm"
              layout="horizontal"
              :labelCol="{span:8}"
              :wrapperCol="{span:16}"
              @submit="handleAppointSubmit"
            >
              <a-row>
                <a-col :span="8">
                  <a-form-item label="服务产品包：">
                    <a-select
                      :size="formSize"
                      placeholder="请选择本次服务产品包"
                      :options="productPackageData"
                      v-decorator="['service_package_id', { rules: [{ required: true, message: '请选择本次服务产品包' }] }]"
                      @change="productPackageChange"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="本次服务内容：">
                    <a-input
                      :size="formSize"
                      allowClear
                      placeholder="请填写本次服务内容"
                      v-decorator="['service_content', { rules: [{ required: true, message: '请填写本次服务内容' }] }]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="客户近期状态：">
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写客户近期状态"
                      v-decorator="['recent_status']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-item label="预约原因：">
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写预约原因"
                      v-decorator="['reason', { rules: [{ required: true, message: '请填写预约原因' }] }]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="16">
                  <a-form-item
                    :labelCol="{span:4}"
                    :wrapperCol="{span:20}"
                    label="客户期望达到效果："
                  >
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写客户期望达到效果"
                      v-decorator="['expectation']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-item label="营养师管理目标：">
                    <a-select
                      :size="formSize"
                      placeholder="请选择营养师管理目标"
                      mode="tags"
                      :allowClear="true"
                      :options="goalSelectArr"
                      optionFilterProp="children"
                      v-decorator="['goal', { rules: [{ required: true, message: '请选择营养师管理目标' }] }]"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="16">
                  <a-form-item
                    :labelCol="{span:4}"
                    :wrapperCol="{span:20}"
                    label="目标备注："
                  >
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写目标备注"
                      v-decorator="['goal_remarks']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">

                </a-col>
                <a-col
                  :span="8"
                  class="text-center"
                >
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="primary"
                      html-type="submit"
                      :size="formSize"
                    >
                      <a-icon type="check" />确认保存
                    </a-button>
                  </a-form-item>
                </a-col>
                <a-col
                  :span="8"
                  class="text-right"
                >
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="link"
                      @click="tabChange(1)"
                    >
                      下一步<a-icon type="arrow-right" />
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-tab-pane>

          <a-tab-pane
            forceRender
            key="2"
            tab="2、近期档案信息"
          >
            <a-form
              :form="consultationForm"
              layout="horizontal"
              :labelCol="{span:8}"
              :wrapperCol="{span:16}"
              @submit="handleConsultationSubmit"
            >
              <a-row>
                <a-col :span="8">
                  <a-form-item label="疾病信息：">
                    <a-select
                      :size="formSize"
                      placeholder="请选择疾病信息"
                      mode="tags"
                      :allowClear="true"
                      :options="diseaseSelectArr"
                      optionFilterProp="children"
                      v-decorator="['disease', { rules: [{ required: true, message: '请选择疾病信息' }] }]"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="疾病信息备注：">
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写疾病信息备注"
                      v-decorator="['disease_remarks']"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="服药及营养补充剂：">
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写服药及营养补充剂信息"
                      v-decorator="['medication']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-item label="家族病史：">
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写家族病史"
                      v-decorator="['family_history']"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="喜好饮食类型：">
                    <a-select
                      :size="formSize"
                      placeholder="请选择喜好饮食类型"
                      :allowClear="true"
                      :options="favoriteDietTypeSelectArr"
                      v-decorator="['favorite_diet_type']"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="喜好饮食类型备注：">
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写喜好饮食类型备注"
                      v-decorator="['favorite_diet_type_remarks']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-item label="喜欢的食物：">
                    <a-tree-select
                      v-decorator="['food_favorite']"
                      style="width: 100%"
                      allowClear
                      treeDataSimpleMode
                      multiple
                      :size="formSize"
                      :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                      :tree-data="foodTreeData"
                      placeholder="请选择喜欢的食物"
                      :filterTreeNode="filterFoodTreeNode"
                    >
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="不喜欢的食物：">
                    <a-tree-select
                      v-decorator="['food_dislike']"
                      style="width: 100%"
                      allowClear
                      treeDataSimpleMode
                      multiple
                      :size="formSize"
                      :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                      :tree-data="foodTreeData"
                      placeholder="请选择不喜欢的食物"
                      :filterTreeNode="filterFoodTreeNode"
                    >
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="过敏食物：">
                    <a-tree-select
                      v-decorator="['allergies']"
                      style="width: 100%"
                      allowClear
                      treeDataSimpleMode
                      multiple
                      :size="formSize"
                      :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                      :tree-data="foodTreeData"
                      placeholder="请选择过敏食物"
                      :filterTreeNode="filterFoodTreeNode"
                    >
                    </a-tree-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-item label="不耐受及敏感食物：">
                    <a-tree-select
                      v-decorator="['food_intolerant']"
                      style="width: 100%"
                      allowClear
                      treeDataSimpleMode
                      multiple
                      :size="formSize"
                      :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                      :tree-data="foodTreeData"
                      placeholder="请选择不耐受食物及敏感食物"
                      :filterTreeNode="filterFoodTreeNode"
                    >
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col :span="16">
                  <a-form-item
                    :labelCol="{span:4}"
                    :wrapperCol="{span:20}"
                    label="缺乏营养："
                  >
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写缺乏营养"
                      v-decorator="['nutrition_deficiency_remarks']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-item label="目前一周运动量：">
                    <a-select
                      :size="formSize"
                      placeholder="请填写选择目前一周运动量"
                      :options="activitySelectArr"
                      v-decorator="['activity_level']"
                      @change="(v) => calorieCalcChange(v, 'consultationForm')"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="预估热量摄入：">
                    <a-input
                      :size="formSize"
                      v-decorator="['calorie']"
                      :style="{ width: '100%' }"
                      type="number"
                      addon-after="Kcal"
                      placeholder="请填写预估热量摄入"
                      :min="0"
                      :step="1"
                      :max="1000000"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item label="客户目前日饮水量：">
                    <a-input
                      :size="formSize"
                      v-decorator="['water_intake']"
                      :style="{ width: '100%' }"
                      type="number"
                      addon-after="mL"
                      placeholder="请填写客户目前日饮水量"
                      :min="0"
                      :step="1"
                      :max="100000"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row>
                <a-col :span="8">
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="link"
                      @click="tabChange(-1)"
                    >
                      <a-icon type="arrow-left" />上一步
                    </a-button>
                  </a-form-item>
                </a-col>
                <a-col
                  :span="8"
                  class="text-center"
                >
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="primary"
                      html-type="submit"
                      :size="formSize"
                    >
                      <a-icon type="check" />确认保存
                    </a-button>
                  </a-form-item>
                </a-col>
                <a-col
                  :span="8"
                  class="text-right"
                >
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="link"
                      @click="tabChange(1)"
                    >
                      下一步<a-icon type="arrow-right" />
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-tab-pane>

          <a-tab-pane
            forceRender
            key="3"
          >
            <span slot="tab">
              <a-badge :offset="[5,0]">
                <span
                  slot="count"
                  class="must-mark"
                >*</span>3、营养方案</a-badge>
            </span>
            <a-form
              :form="nutritionForm"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              @submit="handleNutritionSubmit"
            >
              <a-form-item label="营养评估：">
                <a-textarea
                  :size="formSize"
                  :autoSize="true"
                  placeholder="请填写营养评估"
                  v-decorator="['assessment', { rules: [{ required: true, message: '请填写营养评估' }] }]"
                />
              </a-form-item>
              <a-row>
                <a-col :span="12">
                  <a-form-item
                    label="干预方法："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-select
                      :size="formSize"
                      placeholder="请选择干预方法"
                      :options="interventionMethodArr"
                      mode="multiple"
                      :maxTagCount="2"
                      :maxTagTextLength="7"
                      allowClear
                      v-decorator="['intervention_method', { rules: [{ required: true, message: '请选择干预方法' }] }]"
                      @change="interventionMethodChange"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    label="饮食模式推荐："
                    :label-col="{ span: 5 }"
                    :wrapper-col="{ span: 19 }"
                    :hidden="interventionMethodHide.dpi"
                  >
                    <a-select
                      :size="formSize"
                      placeholder="请选择饮食模式推荐"
                      style="width: calc(100% - 153px);margin-right: 5px;"
                      mode="multiple"
                      :maxTagCount="1"
                      :maxTagTextLength="9"
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      v-decorator="['dietary_pattern_id']"
                      dropdownClassName="dpselect"
                      optionLabelProp="label"
                      @change="adviseCalorieCalcChange"
                    >
                      <a-select-option
                        v-for="(dpv,dpk) in dietaryPatternSelectArr"
                        :key="dpk"
                        :value="dpv.value"
                        :label="dpv.label"
                      >
                        <a-tag
                          color="orange"
                          class="mr-0"
                          v-if="dpv.rec"
                        >
                          推荐
                        </a-tag>
                        {{ dpv.label }}
                      </a-select-option>
                    </a-select>
                    <a-button
                      type="primary"
                      icon="file-protect"
                      :size="formSize"
                      @click="clickshowPattern"
                    >
                      查看饮食模式详情
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-form-item
                label="营养补充剂建议："
                :hidden="interventionMethodHide.supm"
              >
                <a-textarea
                  :size="formSize"
                  :autoSize="true"
                  placeholder="请填写营养补充剂建议"
                  v-decorator="['supplement']"
                />
              </a-form-item>
              <a-form-item label="推荐方案：">
                <a-textarea
                  :size="formSize"
                  :autoSize="true"
                  placeholder="请填写推荐方案"
                  v-decorator="['recommendation']"
                />
              </a-form-item>
              <a-row>
                <a-col :span="8">
                  <a-form-item
                    label="建议一周运动量："
                    :label-col="{ span: 9 }"
                    :wrapper-col="{ span: 15 }"
                  >
                    <a-select
                      :size="formSize"
                      placeholder="请选择建议一周运动量"
                      :options="activitySelectArr"
                      allowClear
                      v-decorator="['advise_activity_level']"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item
                    label="热量摄入建议："
                    :label-col="{ span: 9 }"
                    :wrapper-col="{ span: 15 }"
                  >
                    <a-input
                      :size="formSize"
                      v-decorator="['calorie']"
                      :style="{ width: '100%' }"
                      type="number"
                      addon-after="Kcal"
                      placeholder="热量摄入建议"
                      :min="0"
                      :step="1"
                      :max="1000000"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item
                    label="建议日饮水量："
                    :label-col="{ span: 9 }"
                    :wrapper-col="{ span: 15 }"
                  >
                    <a-input
                      :size="formSize"
                      v-decorator="['advise_water_intake']"
                      :style="{ width: '100%' }"
                      type="number"
                      addon-after="mL"
                      placeholder="建议日饮水量"
                      :min="0"
                      :step="1"
                      :max="100000"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">
                  <a-form-item
                    label="碳水摄入建议："
                    :label-col="{ span: 9 }"
                    :wrapper-col="{ span: 15 }"
                  >
                    <a-slider
                      v-decorator="['carbohydrates']"
                      :min="0"
                      :step="1"
                      :max="100"
                      :marks="cpfMarks.carbohydrates"
                      :tipFormatter="(v)=> { return `${v}%` }"
                      @afterChange="(v) => this.cpfChange(v, 'carbohydrates')"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item
                    label="蛋白质摄入："
                    :label-col="{ span: 9 }"
                    :wrapper-col="{ span: 15 }"
                  >
                    <a-slider
                      v-decorator="['protein']"
                      :min="0"
                      :step="1"
                      :max="100"
                      :marks="cpfMarks.protein"
                      :tipFormatter="(v)=> { return `${v}%` }"
                      @afterChange="(v) => this.cpfChange(v, 'protein')"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item
                    label="脂肪摄入建议："
                    :label-col="{ span: 9 }"
                    :wrapper-col="{ span: 15 }"
                  >
                    <a-slider
                      v-decorator="['fat']"
                      :min="0"
                      :step="1"
                      :max="100"
                      :marks="cpfMarks.fat"
                      :tipFormatter="(v)=> { return `${v}%` }"
                      @afterChange="(v) => this.cpfChange(v, 'fat')"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <template v-if="cuProductPackageTags === false || cuProductPackageTags.indexOf('1') >= 0 || cuProductPackageTags.indexOf('2') >= 0">
                <a-divider style="margin: 5px 0;" />
                <p class="litle-title">一日食谱建议：
                  <a-button
                    type="primary"
                    :size="formSize"
                    icon="file-done"
                    class="float-right"
                    @click="clickFoodMenu"
                    v-if="cuProductPackageTags === false || cuProductPackageTags.indexOf('2') >= 0"
                  >
                    专业食谱定制
                  </a-button>
                </p>
                <a-row>
                  <a-col :span="12">
                    <a-form-item
                      label="早餐："
                      :label-col="{ span: 6 }"
                      :wrapper-col="{ span: 18 }"
                    >
                      <a-textarea
                        :size="formSize"
                        :autoSize="true"
                        placeholder="请填写早餐"
                        v-decorator="['breakfast']"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item
                      label="早加餐："
                      :label-col="{ span: 6 }"
                      :wrapper-col="{ span: 18 }"
                    >
                      <a-textarea
                        :size="formSize"
                        :autoSize="true"
                        placeholder="请填写早加餐"
                        v-decorator="['snack_morning']"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-item
                      label="午餐："
                      :label-col="{ span: 6 }"
                      :wrapper-col="{ span: 18 }"
                    >
                      <a-textarea
                        :size="formSize"
                        :autoSize="true"
                        placeholder="请填写午餐"
                        v-decorator="['lunch']"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item
                      label="午加餐："
                      :label-col="{ span: 6 }"
                      :wrapper-col="{ span: 18 }"
                    >
                      <a-textarea
                        :size="formSize"
                        :autoSize="true"
                        placeholder="请填写午加餐"
                        v-decorator="['snack_afternoon']"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-form-item label="晚餐：">
                  <a-textarea
                    :size="formSize"
                    :autoSize="true"
                    placeholder="请填写晚餐"
                    v-decorator="['supper']"
                  />
                </a-form-item>
              </template>
            </a-form>
            <template v-if="cuProductPackageTags === false || cuProductPackageTags.indexOf('3') >= 0">
              <a-form
                :form="nutritionSportForm"
                :label-col="{ span: 3 }"
                :wrapper-col="{ span: 21 }"
              >
                <a-divider style="margin: 5px 0;" />
                <p class="litle-title mb-0">生活干预、运动计划：</p>
                <a-row>
                  <a-col :span="12">
                    <a-form-item
                      label="生活干预："
                      :label-col="{ span: 6 }"
                      :wrapper-col="{ span: 18 }"
                    >
                      <a-textarea
                        :size="formSize"
                        :autoSize="true"
                        placeholder="请填写生活干预"
                        v-decorator="['life_plan']"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-item
                      label="运动计划："
                      :label-col="{ span: 6 }"
                      :wrapper-col="{ span: 18 }"
                    >
                      <a-textarea
                        :size="formSize"
                        :autoSize="true"
                        placeholder="请填写运动计划"
                        v-decorator="['sport_plan']"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-divider style="margin: 5px 0;" />
                <p class="litle-title mb-0">
                  运动内容推荐：
                  <span class="float-right font-error">温馨提示：请优先确认产品包！选择产品包时会重置下方运动内容推荐表单！</span>
                </p>
                <div
                  v-for="(snum,idx) in nutritionSportForm.getFieldValue('sportKeys')"
                  :key="idx"
                >
                  <a-divider
                    v-if="idx > 0"
                    style="margin: 5px 0;"
                  />
                  <a-row>
                    <a-col
                      :span="2"
                      v-if="idx > 0"
                    >
                      <a-button
                        icon="close"
                        type="danger"
                        :size="formSize"
                        style="margin-top: 8px;"
                        @click="clickRemoveSport(idx)"
                      >
                        删除
                      </a-button>
                    </a-col>
                    <a-col :span="idx > 0 ? 10 : 12">
                      <a-form-item
                        :label="'运动方式#'+(snum+1)+'：'"
                        :label-col="{ span: 6 }"
                        :wrapper-col="{ span: 18 }"
                      >
                        <a-select
                          :size="formSize"
                          placeholder="请选择运动方式"
                          :options="sportTypeSelectArr"
                          v-decorator="[`sport_type[${snum}]`, { preserve: true }]"
                        >
                        </a-select>
                        <a-input
                          type="hidden"
                          v-decorator="[`sportId[${snum}]`, { preserve: true }]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item
                        :label="'运动时间#'+(snum+1)+'：'"
                        :label-col="{ span: 6 }"
                        :wrapper-col="{ span: 18 }"
                      >
                        <a-textarea
                          :size="formSize"
                          :autoSize="true"
                          placeholder="请填写运动时间"
                          v-decorator="[`duration[${snum}]`, { preserve: true }]"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="12">
                      <a-form-item
                        :label="'运动频率#'+(snum+1)+'：'"
                        :label-col="{ span: 6 }"
                        :wrapper-col="{ span: 18 }"
                      >
                        <a-textarea
                          :size="formSize"
                          :autoSize="true"
                          placeholder="请填写运动频率"
                          v-decorator="[`frequency[${snum}]`, { preserve: true }]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item
                        :label="'运动举例#'+(snum+1)+'：'"
                        :label-col="{ span: 6 }"
                        :wrapper-col="{ span: 18 }"
                      >
                        <a-textarea
                          :size="formSize"
                          :autoSize="true"
                          placeholder="请填写运动举例"
                          v-decorator="[`example[${snum}]`, { preserve: true }]"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-form-item :label="'运动方案#'+(snum+1)+'：'">
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写运动方案"
                      v-decorator="[`plan_content[${snum}]`, { preserve: true }]"
                    />
                  </a-form-item>
                </div>
              </a-form>
            </template>
            <a-row class="mt-5">
              <a-col :span="4">
                <a-form-item
                  :labelCol="{span:0}"
                  :wrapperCol="{span:24}"
                >
                  <a-button
                    type="link"
                    @click="tabChange(-1)"
                  >
                    <a-icon type="arrow-left" />上一步
                  </a-button>
                </a-form-item>
              </a-col>
              <a-col
                :span="16"
                class="text-center"
              >
                <a-button
                  icon="plus"
                  type="danger"
                  ghost
                  :size="formSize"
                  @click="clickAddSport"
                  v-if="cuProductPackageTags === false || cuProductPackageTags.indexOf('3') >= 0"
                >
                  继续添加运动内容
                </a-button>

                <a-button
                  icon="check"
                  @click="handleNutritionSubmit"
                  type="primary"
                  :size="formSize"
                  class="ml-10 mr-20"
                >确认保存</a-button>

                <a-checkbox-group
                  v-model="nutririonSameCheck"
                  :options="[{label:'以上信息同步更新到客户档案',value:1}]"
                />
              </a-col>
              <a-col
                :span="4"
                class="text-right"
              >
                <a-form-item
                  :labelCol="{span:0}"
                  :wrapperCol="{span:24}"
                >
                  <a-button
                    type="link"
                    @click="tabChange(1)"
                  >
                    下一步<a-icon type="arrow-right" />
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-tab-pane>

          <a-tab-pane
            forceRender
            key="4"
            tab="4、问卷调查"
            :disabled="!(cuProductPackageTags === false || cuProductPackageTags.indexOf('4') >= 0)"
          >
            <div>
              <a-row class="mb-10">
                <a-col
                  :offset="21"
                  :span="3"
                  class="text-right"
                >
                  <a-button
                    icon="form"
                    @click="clickSurveyList"
                    type="primary"
                  >填写新问卷</a-button>
                </a-col>
              </a-row>
              <a-table
                :columns="surveyColumns"
                :data-source="surveyListData"
                rowKey="id"
                bordered
                :pagination="false"
              >
                <span
                  slot="is_finished"
                  slot-scope="text"
                  :class="text >= 1 ? 'font-success' : 'font-error'"
                >
                  {{ text >= 1 ? '是' : '否' }}
                </span>
                <span
                  slot="status"
                  slot-scope="text"
                >
                  <a-badge
                    :status="text | statusTypeFilter"
                    :text="'survey_response.status' | showText(text)"
                  />
                </span>
                <span
                  slot="action"
                  slot-scope="text, record"
                >
                  <template>
                    <span>
                      <a @click="clickViewSruveyRes(record)">查看</a>
                    </span>
                    <a-divider
                      type="vertical"
                      v-if="record.status.toString() === '0'"
                    />
                    <a
                      v-if="record.status.toString() === '0'"
                      @click="clickEditSruveyRes(record)"
                    >编辑</a>
                    <a-divider type="vertical" />
                    <a-popconfirm
                      title="确定将当前问卷填报数据删除？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="clickDelSruveyRes(record)"
                    >
                      <a class="font-error">删除</a>
                    </a-popconfirm>
                  </template>
                </span>
              </a-table>
              <a-row class="mt-5">
                <a-col :span="4">
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="link"
                      @click="tabChange(-1)"
                    >
                      <a-icon type="arrow-left" />上一步
                    </a-button>
                  </a-form-item>
                </a-col>
                <a-col
                  :span="16"
                  class="text-center"
                >

                </a-col>
                <a-col
                  :span="4"
                  class="text-right"
                >
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="link"
                      @click="tabChange(1)"
                    >
                      下一步<a-icon type="arrow-right" />
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </a-tab-pane>

          <a-tab-pane
            forceRender
            key="5"
            tab="5、随访计划"
            :disabled="!(cuProductPackageTags === false || cuProductPackageTags.indexOf('5') >= 0)"
          >
            <a-form
              :form="followupForm"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              @submit="handleFollowupSubmit"
            >
              <a-row>
                <a-col :span="12">
                  <a-form-item
                    label="随访方式："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-select
                      :size="formSize"
                      placeholder="请选择随访方式"
                      :options="followupMethodSelectArr"
                      v-decorator="['method', { rules: [{ required: true, message: '请选择随访方式' }] }]"
                    >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    label="总次数："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-input
                      :size="formSize"
                      v-decorator="['all_count', { rules: [{ required: true, message: '请填写随访总次数' }] }]"
                      :style="{ width: '100%' }"
                      type="number"
                      addon-after="次"
                      placeholder="请填写随访总次数"
                      :min="0"
                      :step="1"
                      :max="1000"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-form-item
                    label="随访周期："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写随访周期"
                      v-decorator="['cycle']"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    label="备注："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写随访备注"
                      v-decorator="['remarks']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row class="mt-5">
                <a-col :span="4">
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="link"
                      @click="tabChange(-1)"
                    >
                      <a-icon type="arrow-left" />上一步
                    </a-button>
                  </a-form-item>
                </a-col>
                <a-col
                  :span="16"
                  class="text-center"
                >
                  <a-button
                    icon="check"
                    html-type="submit"
                    type="primary"
                    :size="formSize"
                    class="ml-10 mr-20"
                  >确认保存</a-button>
                </a-col>
                <a-col
                  :span="4"
                  class="text-right"
                >
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="link"
                      @click="tabChange(1)"
                    >
                      下一步<a-icon type="arrow-right" />
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-tab-pane>

          <a-tab-pane
            forceRender
            key="6"
            tab="6、指标检测计划"
            :disabled="!(cuProductPackageTags === false || cuProductPackageTags.indexOf('6') >= 0)"
          >
            <a-form
              :form="monitorForm"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              @submit="handleMonitorSubmit"
            >
              <a-row>
                <a-col :span="12">
                  <a-form-item
                    label="发现的问题："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写发现的问题"
                      v-decorator="['problem']"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    label="解决方案："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写解决方案"
                      v-decorator="['solution']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-form-item
                    label="是否有不良反应："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-radio-group
                      :size="formSize"
                      v-decorator="['is_reaction', {initialValue:'0', rules: [{ required: true, message: '请选择是否有不良反应' }] }]"
                      button-style="solid"
                    >
                      <a-radio-button value="0">
                        无
                      </a-radio-button>
                      <a-radio-button value="1">
                        有
                      </a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    label="执行程度及调整："
                    :label-col="{ span: 6 }"
                    :wrapper-col="{ span: 18 }"
                  >
                    <a-textarea
                      :size="formSize"
                      :autoSize="true"
                      placeholder="请填写执行程度及调整"
                      v-decorator="['content']"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row class="mt-5">
                <a-col :span="4">
                  <a-form-item
                    :labelCol="{span:0}"
                    :wrapperCol="{span:24}"
                  >
                    <a-button
                      type="link"
                      @click="tabChange(-1)"
                    >
                      <a-icon type="arrow-left" />上一步
                    </a-button>
                  </a-form-item>
                </a-col>
                <a-col
                  :span="16"
                  class="text-center"
                >
                  <a-button
                    icon="check"
                    html-type="submit"
                    type="primary"
                    :size="formSize"
                    class="ml-10 mr-20"
                  >确认保存</a-button>
                </a-col>
                <a-col :span="4">

                </a-col>
              </a-row>
            </a-form>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <a-empty
        v-else
        description="数据加载中..."
      />

      <!-- 档案管理抽屉窗口 -->
      <patient-archive-manage
        :parentParams="patArchiveParam"
        :archiveDrawerVisible="archiveDrawerVisible"
        @archiveDrawerVisibleChange="archiveDrawerVisibleChange"
      />

      <!-- 饮食模式的抽屉窗口 -->
      <dietary-pattern-detail
        :dietaryPatternDrawerVisible="dietaryPatternDrawerVisible"
        :parentParams="dietaryPatternParams"
        @dietaryPatternDrawerVisibleChange="dietaryPatternDrawerVisibleChange"
      />

      <!-- 问卷调查列表抽屉 -->
      <SurveyList
        ref="surveyListDrawer"
        @complete="surveyReload"
      />

      <!-- 问卷编辑的抽屉窗口 -->
      <survey-input
        ref="surveyInputDrawer"
        @complete="loadSurveyResponseList(true)"
      />

      <!-- 问卷填写查看的抽屉窗口 -->
      <survey-response-view ref="surveyResponseViewDrawer" />
    </a-spin>
  </div>
</template>

<script>
import { getPatientNewestBodyInfo, getPatientInfo, getPatientMedicalInfo, getPatientDietaryInfo, getPatientPersonalInfo, savePatientNutritionInfo, getPatientNutritionInfo } from '@/api/dms/patient'
import { selectListDietaryPattern } from '@/api/dms/dietaryPattern'
import { savePreConsultationInfo, getPreConsultationInfo } from '@/api/dms/consultation'
import { savePatientServiceRecordOnly, getServiceRecordInfo } from '@/api/dms/serviceRecord'
import { getNewestNotDealAppointmentInfo } from '@/api/dms/clientAppointment'
import { savePatientFollowPlanInfo, getPatientFollowPlanInfo } from '@/api/dms/followup'
import { savePatientMonitorRecordInfo, getPatientMonitorRecordInfo } from '@/api/dms/monitorRecord'
import { getSelectListTag } from '@/api/manage'
import { listSurveyResponse, surveyResponseDelete } from '@/api/dms/surveyResponse'
import { selectTreeDefData } from '@/api/dms/fsItem'
import { getProductPackageSelectList } from '@/api/dms/productPackage'
import { PatientArchiveManage } from '@/components/PatientCards'
import { DietaryPatternDetail } from '@/components/DietaryPattern'
import { SurveyList, SurveyInput, SurveyResponseView } from '@/components/Surveys'
import moment from 'moment'

export default {
  name: 'SetServiceRecord',
  components: {
    SurveyList,
    SurveyInput,
    SurveyResponseView,
    DietaryPatternDetail,
    PatientArchiveManage
  },
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      patientInfo: {},
      bodyInfo: [],
      patArchiveParam: {},
      archiveDrawerVisible: false,
      recordOri: { appoint_id: 0 },
      appointForm: this.$form.createForm(this),
      consultationForm: this.$form.createForm(this),
      nutritionForm: this.$form.createForm(this),
      nutritionSportForm: this.$form.createForm(this),
      followupForm: this.$form.createForm(this),
      monitorForm: this.$form.createForm(this),
      formSize: 'small',
      goalSelectArr: [],
      diseaseSelectArr: [],
      activitySelectArr: [],
      favoriteDietTypeSelectArr: [],
      appointSameCheck: [1],
      nutririonSameCheck: [1],
      foodTreeData: [],
      nutritionOri: {},
      medicalInfo: {},
      dietaryInfo: {},
      personalInfo: {},
      interventionMethodArr: [],
      interventionMethodHide: { dpi: true, supm: true },
      dietaryPatternSelectArr: [],
      dietaryPatternDrawerVisible: false,
      dietaryPatternParams: {},
      cpfOri: { carbohydrates: 55, protein: 15, fat: 30 },
      cpfMarks: { carbohydrates: { 55: '55%' }, protein: { 15: '15%' }, fat: { 30: '30%' } },
      sportTypeSelectArr: [],
      sportForNum: 0,
      sportOriObj: {},
      followupOri: {},
      monitorOri: {},
      preConsultationOri: {},
      surveyListData: [],
      surveyHasIds: [],
      surveyColumns: [
        {
          title: '序号',
          width: 65,
          dataIndex: 'id',
          align: 'center',
          customRender: (text, row, key) => {
            return key + 1
          }
        },
        {
          title: '问卷标题',
          // width: 180,
          ellipsis: true,
          dataIndex: 'title'
        },
        {
          title: '问卷填写开始 - 结束时间',
          // width: 150,
          ellipsis: true,
          dataIndex: 'end_time',
          customRender: (text, row) => {
            return (row.start_time === null ? '' : row.start_time) + ' - ' + (text === null ? '' : text)
          }
        },
        {
          title: '耗时（秒）',
          width: 100,
          align: 'center',
          ellipsis: true,
          dataIndex: 'duration_time'
        },
        {
          title: '是否完成',
          width: 80,
          align: 'center',
          dataIndex: 'is_finished',
          scopedSlots: { customRender: 'is_finished' }
        },
        {
          title: '当前状态',
          width: 80,
          dataIndex: 'status',
          align: 'center',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 140,
          scopedSlots: { customRender: 'action' }
        }
      ],
      followupMethodSelectArr: [],
      productPackageData: [],
      cuProductPackageTags: false,
      activeTabKey: '1',
      showNotificationMark: true,
      showNotificationKey: 'showNotificationKey'
    }
  },
  filters: {
    statusTypeFilter (status) {
      return status.toString() === '10' ? 'success' : 'error'
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.setServiceRecord'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    // console.log(this.params)
    this.params.serviceRecord = 0
    this.spinning = true
    // 获取体格信息
    getPatientNewestBodyInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res2) => {
      if (res2.status !== undefined && res2.status === 1) {
        this.bodyInfo = res2.data
      }
    })
    // 获取病史及用药信息
    getPatientMedicalInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
        this.medicalInfo = res.data
        if (this.medicalInfo.disease !== undefined && this.medicalInfo.disease !== '' && this.medicalInfo.disease !== null) {
          this.medicalInfo.disease = JSON.parse(this.medicalInfo.disease)
        }
      }
    })
    this.loadDietaryInfo()
    // 生活习惯
    getPatientPersonalInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
        this.personalInfo = res.data
        this.personalInfo.goal = JSON.parse(this.personalInfo.goal)
      }
    })
    // 获取饮食模式下拉数据
    selectListDietaryPattern({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      if (res.status !== undefined && res.status === 1) {
        this.dietaryPatternSelectArr = res.data
      }
    })
    // 20目标、1疾病的标签下拉数据
    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['1'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.diseaseSelectArr = res.data
      }
    })
    getSelectListTag({ enterprise: this.$store.getters.userInfo.user.target_enterprise, tag_type: ['0', '1', '20'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.goalSelectArr = res.data
      }
    })
    // 食物tree数据
    selectTreeDefData({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.foodTreeData = res.data
        console.log(res.data)
      }
    })
    // 产品包下拉
    getProductPackageSelectList({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.productPackageData = res.data.map(ppv => {
          ppv.dietary_pattern_ids = []
          if (ppv.product_package_dietary_pattern !== undefined && ppv.product_package_dietary_pattern.length > 0) {
            ppv.product_package_dietary_pattern.map(ppdpv => {
              ppv.dietary_pattern_ids.push(ppdpv.pattern_id)
            })
          }
          return ppv
        })
        // console.log(this.productPackageData)
      }
    })
    // 获取客户基础信息
    getPatientInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['diseasetag'] }).then((res) => {
      if (res.status !== undefined && res.status === 1 && res.data !== undefined && res.data.id !== undefined && res.data.id === this.params.patient) {
        this.patientInfo = res.data
        this.activitySelectArr = this.$options.filters.selectOptionsData('calorie.calc')
        this.favoriteDietTypeSelectArr = this.$options.filters.selectOptionsData('patient.favorite_diet_type')
        this.interventionMethodArr = this.$options.filters.selectOptionsData('prescription_nutrition.intervention_method')
        this.sportTypeSelectArr = this.$options.filters.selectOptionsData('patient.activity_type')
        this.followupMethodSelectArr = this.$options.filters.selectOptionsData('followup.method')
        // 左侧form的id记录的是问诊调查health_prescription_consultations的id
        this.appointForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
        this.appointForm.getFieldDecorator('create_user', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
        this.appointForm.getFieldDecorator('domain_platform_id', { initialValue: this.$store.getters.userInfo.platform.id, preserve: true, rules: [{ required: true }] })

        this.consultationForm.getFieldDecorator('id', { preserve: true })
        this.consultationForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
        this.consultationForm.getFieldDecorator('create_user', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
        this.consultationForm.getFieldDecorator('domain_platform_id', { initialValue: this.$store.getters.userInfo.platform.id, preserve: true, rules: [{ required: true }] })

        this.nutritionForm.getFieldDecorator('id', { preserve: true })
        this.nutritionForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
        this.nutritionForm.getFieldDecorator('create_user', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
        this.nutritionSportForm.getFieldDecorator('sportKeys', { initialValue: [], preserve: true })
        this.clickAddSport()
        this.followupForm.getFieldDecorator('id')
        this.followupForm.getFieldDecorator('create_user', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
        this.monitorForm.getFieldDecorator('id')
        this.monitorForm.getFieldDecorator('create_user', { initialValue: this.$store.getters.userInfo.user.id, preserve: true, rules: [{ required: true }] })
        this.monitorForm.getFieldDecorator('enterprise_id', { initialValue: this.$store.getters.userInfo.user.target_enterprise, preserve: true, rules: [{ required: true }] })
        // 判断是否需要获取服务数据
        this.loadOriServiceInfo()
      } else {
        this.spinning = false
        this.$message.error(res.msg || '获取信息失败！请刷新后重试！')
        return this.$router.go(-1)
      }
    }).catch((e) => {
      console.log(e)
      return this.$router.go(-1)
    })
  },
  methods: {
    moment,
    loadDietaryInfo () {
      // 饮食习惯
      getPatientDietaryInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          this.dietaryInfo = res.data
          this.dietaryInfo.favorite_diet_type = JSON.parse(this.dietaryInfo.favorite_diet_type)
          this.dietaryInfo.dietary_pattern_id = JSON.parse(this.dietaryInfo.dietary_pattern_id)
          this.dietaryInfo.food_favorite = JSON.parse(this.dietaryInfo.food_favorite)
          this.dietaryInfo.food_dislike = JSON.parse(this.dietaryInfo.food_dislike)
          this.dietaryInfo.allergies = JSON.parse(this.dietaryInfo.allergies)
          this.dietaryInfo.food_intolerant = JSON.parse(this.dietaryInfo.food_intolerant)
        }
      })
    },
    clickShowManage () {
      // 档案管理弹窗
      this.patArchiveParam = { patient: this.params.patient }
      if (this.recordOri.id !== undefined && this.recordOri.id > 0) {
        this.patArchiveParam.recordOri = this.recordOri.id
      }
      this.archiveDrawerVisible = true
    },
    archiveDrawerVisibleChange (v) {
      this.archiveDrawerVisible = v
    },
    loadOriServiceInfo () {
      // 检测是否有原记录id，没有原记录才需要去判断预约记录id
      if (this.params.record !== undefined && this.params.record > 0) {
        // 获取主记录数据 
        const getRecParam = { patient_id: this.params.patient, enterprise_id: this.$store.getters.userInfo.user.target_enterprise, service_user: this.$store.getters.userInfo.user.id, id: this.params.record }
        getServiceRecordInfo(getRecParam).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.recordOri = res.data
            // 数据获取成功则加载相关的营养方案，加载完了才能加载后面的随访计划
            this.loadOriNutritionInfo(true, true)
            this.loadPreConsultationInfo()
            // 获取已填报问卷数据
            // this.loadSurveyResponseList()
          } else {
            this.$message.error(res.msg || '获取原始信息失败！请刷新后重试！')
          }
        })
      } else {
        // 检测是否有预约记录id
        if (this.params.appoint !== undefined && this.params.appoint > 0) {
          // 比如首页会传入预约记录ID则直接赋值
          this.$nextTick(() => {
            this.recordOri.appoint_id = this.params.appoint
            this.spinning = false
          })
        } else {
          // 客户是否有预约信息最新的未处理预约记录
          getNewestNotDealAppointmentInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, dep: this.$store.getters.userInfo.user.department_id, user: this.$store.getters.userInfo.user.id }).then((res) => {
            this.spinning = false
            if (res.status !== undefined && res.status === 1) {
              this.recordOri.appoint_id = res.data.id
            }
          })
        }
        const timmer = setInterval(() => {
          if (this.spinning === false) {
            this.initAppointFormData()
            clearInterval(timmer)
          }
        }, 100)
      }
    },
    loadOriNutritionInfo (setValMark = true, loadNext = false, onlySetId = false) {
      // 加载营养方案数据
      getPatientNutritionInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((resNut) => {
        if (resNut.status !== undefined && resNut.status === 1 && resNut.data.id !== undefined && resNut.data.id > 0) {
          this.nutritionOri = resNut.data
          // this.nutritionOri.diet_type = JSON.parse(this.nutritionOri.diet_type)
          this.nutritionOri.favorite_diet_type = JSON.parse(this.nutritionOri.favorite_diet_type)
          this.nutritionOri.dietary_pattern_id = JSON.parse(this.nutritionOri.dietary_pattern_id)
          if (onlySetId) {
            this.nutritionForm.setFieldsValue({ id: resNut.data.id })
          }
          if (setValMark) {
            // 保存后需要重置回显数据
            this.resetNutritionFormData()
            this.spinning = false
          }
          if (loadNext) {
            this.loadOriMoreInfo()
          }
        } else {
          this.nutritionForm.setFieldsValue(Object.assign({}, this.cpfOri))
          this.spinning = false
        }
      })
    },
    resetNutritionFormData () {
      if (this.nutritionOri.id !== undefined && this.nutritionOri.id > 0) {
        let setNutArr = JSON.parse(JSON.stringify(this.nutritionOri))
        // 处理饮食模式回显
        delete setNutArr.prescription_sport
        setNutArr.intervention_method = (setNutArr.intervention_method !== null && setNutArr.intervention_method !== '') ? setNutArr.intervention_method.split(',') : []
        // 触发干预方法的修改事件
        this.interventionMethodChange(setNutArr.intervention_method, false)
        // console.log(setNutArr)

        this.nutritionForm.setFieldsValue(setNutArr)
        // 处理碳水蛋白脂肪
        setNutArr.carbohydrates = setNutArr.carbohydrates <= 0 ? 0 : setNutArr.carbohydrates
        setNutArr.protein = setNutArr.protein <= 0 ? 0 : setNutArr.protein
        setNutArr.fat = setNutArr.fat <= 0 ? 0 : setNutArr.fat
        this.cpfMarks = {
          carbohydrates: { [setNutArr.carbohydrates]: setNutArr.carbohydrates + '%' },
          protein: { [setNutArr.protein]: setNutArr.protein + '%' },
          fat: { [setNutArr.fat]: setNutArr.fat + '%' }
        }
        this.resetNutritionSportFormData()
      } else {
        // 回显档案中的数据
        if (this.dietaryInfo.id !== undefined && this.dietaryInfo.id > 0) {
          let setNutArrByDietary = JSON.parse(JSON.stringify(this.dietaryInfo))
          delete setNutArrByDietary.id
          this.nutritionForm.setFieldsValue(setNutArrByDietary)
          // 处理碳水蛋白脂肪
          setNutArrByDietary.carbohydrates = setNutArrByDietary.carbohydrates <= 0 ? 0 : setNutArrByDietary.carbohydrates
          setNutArrByDietary.protein = setNutArrByDietary.protein <= 0 ? 0 : setNutArrByDietary.protein
          setNutArrByDietary.fat = setNutArrByDietary.fat <= 0 ? 0 : setNutArrByDietary.fat
          this.cpfMarks = {
            carbohydrates: { [setNutArrByDietary.carbohydrates]: setNutArrByDietary.carbohydrates + '%' },
            protein: { [setNutArrByDietary.protein]: setNutArrByDietary.protein + '%' },
            fat: { [setNutArrByDietary.fat]: setNutArrByDietary.fat + '%' }
          }
          if (setNutArrByDietary.advise_activity_level !== null && setNutArrByDietary.advise_activity_level !== '') {
            // 触发建议一周运动量的事件
            this.adviseCalorieCalcChange(setNutArrByDietary.advise_activity_level)
          }
        }
      }
    },
    resetNutritionSportFormData () {
      // 处理运动计划 0开始计数
      if (this.nutritionOri.prescription_sport !== undefined && this.nutritionOri.prescription_sport.length > 0) {
        this.sportForNum = 0
        // console.log(this.nutritionOri)
        const sportKeysNew = []
        const sportIdNew = []
        const sportTypeNew = []
        const durationNew = []
        const frequencyNew = []
        const exampleNew = []
        const planNew = []
        this.nutritionOri.prescription_sport.map((ppv, ppi) => {
          sportKeysNew[this.sportForNum] = this.sportForNum
          sportIdNew[this.sportForNum] = ppv.id
          sportTypeNew[this.sportForNum] = ppv.sport_type.toString()
          durationNew[this.sportForNum] = ppv.duration
          frequencyNew[this.sportForNum] = ppv.frequency
          exampleNew[this.sportForNum] = ppv.example
          planNew[this.sportForNum] = ppv.plan_content
          this.sportForNum++
        })
        this.spinning = true
        this.nutritionSportForm.setFieldsValue({
          sportKeys: sportKeysNew
        })
        const that = this
        const timer = setTimeout(() => {
          that.nutritionSportForm.setFieldsValue({
            sportId: sportIdNew,
            sport_type: sportTypeNew,
            duration: durationNew,
            frequency: frequencyNew,
            example: exampleNew,
            plan_content: planNew,
          })
          clearTimeout(timer)
          that.spinning = false
        }, 1000)
      }
      // this.nutritionSportIdx = 0
    },
    loadOriMoreInfo () {
      // 加载随访计划数据
      getPatientFollowPlanInfo({ patient: this.params.patient, service_record: this.recordOri.id }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          res.data.method = res.data.method.toString()
          this.followupOri = res.data
        }
      })
      // 加载指标监测计划数据
      getPatientMonitorRecordInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((res2) => {
        if (res2.status !== undefined && res2.status === 1 && res2.data.id !== undefined && res2.data.id > 0) {
          res2.data.is_reaction = res2.data.is_reaction.toString()
          this.monitorOri = res2.data
        }

        this.spinning = false
      })
    },
    loadPreConsultationInfo () {
      // 加载问诊调查数据
      getPreConsultationInfo({ patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise, service_record: this.recordOri.id }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data.id !== undefined && res.data.id > 0) {
          res.data.goal = JSON.parse(res.data.goal)
          res.data.disease = JSON.parse(res.data.disease)
          this.preConsultationOri = res.data
        }
      })
    },
    initAppointFormData () {
      // 初始form数据
      let iniAppointDt = {}
      if (this.personalInfo.id !== undefined && this.personalInfo.id > 0) {
        iniAppointDt = { reason: this.personalInfo.reason, expectation: this.personalInfo.expectation, goal: this.personalInfo.goal, goal_remarks: this.personalInfo.goal_remarks }
      }
      iniAppointDt.service_content = '问诊服务内容 ' + moment().format('YYYYMMDDHHmmss')
      this.appointForm.setFieldsValue(iniAppointDt)
      if (this.medicalInfo.id !== undefined && this.medicalInfo.id > 0) {
        this.consultationForm.setFieldsValue({ disease: this.medicalInfo.disease, disease_remarks: this.medicalInfo.disease_remarks, medication: this.medicalInfo.medication, personal_history: this.medicalInfo.personal_history, family_history: this.medicalInfo.family_history, other_remarks: this.medicalInfo.other_remarks })
      }
      if (this.dietaryInfo.id !== undefined && this.dietaryInfo.id > 0) {
        this.consultationForm.setFieldsValue({ favorite_diet_type: this.dietaryInfo.favorite_diet_type, favorite_diet_type_remarks: this.dietaryInfo.favorite_diet_type_remarks, food_favorite: this.dietaryInfo.food_favorite, food_dislike: this.dietaryInfo.food_dislike, allergies: this.dietaryInfo.allergies, food_intolerant: this.dietaryInfo.food_intolerant, nutrition_deficiency_remarks: this.dietaryInfo.nutrition_deficiency_remarks, water_intake: this.dietaryInfo.water_intake, activity_level: this.dietaryInfo.activity_level, calorie: this.dietaryInfo.calorie })
      }
      this.nutritionForm.setFieldsValue(Object.assign({}, this.cpfOri))
      this.resetNutritionFormData()
    },
    handleAppointSubmit (e) {
      e.preventDefault()
      this.appointForm.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.addNewServiceRecord()
        } else {
          this.$message.error('请确认客户主诉信息必填项填写完成！')
        }
      })
    },
    handleConsultationSubmit (e) {
      e.preventDefault()
      this.consultationForm.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (this.nutritionOri.id !== undefined) {
            values.nutrition_id = this.nutritionOri.id
          }
          // 将档案相关的id传过去，同步更新的时候需要
          if (this.dietaryInfo.id !== undefined && this.dietaryInfo.id > 0) {
            values.dietary_id = this.dietaryInfo.id
          }
          if (this.medicalInfo.id !== undefined && this.medicalInfo.id > 0) {
            values.medical_id = this.medicalInfo.id
          }
          this.savePreConsultation(Object.assign({ patient_id: this.params.patient, service_record_id: this.recordOri.id, isSame: this.appointSameCheck }, values))
        } else {
          this.$message.error('请确认客户客户近期档案信息必填项填写完成！')
        }
      })
    },
    savePreConsultation (preConsDt = {}) {
      this.spinning = true
      this.tip = '处理中...'
      // console.log(preConsDt)

      savePreConsultationInfo(preConsDt).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('保存成功！')
          this.loadDietaryInfo()
          this.loadPreConsultationInfo()
          // 不重新赋值营养方案的form。只赋值营养方案的id避免savePreConsultationInfo时同步新增的营养方案都是空值的情况
          this.loadOriNutritionInfo(false, false, true)
          this.consultationForm.setFieldsValue({ id: res.data.id })
        } else {
          this.$message.error(res.msg || '服务信息保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    addNewServiceRecord () {
      this.spinning = true
      this.tip = '处理中...'
      const cuAptData = this.appointForm.getFieldsValue()
      if (cuAptData.service_package_id === undefined || cuAptData.service_package_id === null || cuAptData.service_package_id === '' || cuAptData.service_content === undefined || cuAptData.service_content === null || cuAptData.service_content === '') {
        this.$message.error('请确认先确认客户主诉中的本次服务产品包和内容！')
        this.spinning = false
        return false
      }
      let saveRecParm = { patient_id: this.params.patient, enterprise_id: cuAptData.enterprise_id, create_user: cuAptData.create_user, client_appoint_id: this.recordOri.appoint_id, service_content: cuAptData.service_content, service_package_id: cuAptData.service_package_id }
      if (this.recordOri.id !== undefined && this.recordOri.id > 0) {
        saveRecParm.id = this.recordOri.id
      }
      savePatientServiceRecordOnly(saveRecParm).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          this.recordOri.id = res.data.record
          const preConsDt = Object.assign({ patient_id: this.params.patient, service_record_id: this.recordOri.id, isSame: this.appointSameCheck }, cuAptData)
          if (this.personalInfo.id !== undefined && this.personalInfo.id > 0) {
            preConsDt.personal_id = this.personalInfo.id
          }
          this.savePreConsultation(preConsDt)
        } else {
          this.$message.error(res.msg || '客户主诉同步失败！请刷新后重试！')
        }
      })
    },
    calorieCalcChange (v, formApiObj) {
      // 写个中间默认值
      let cuBmr = 1600
      for (let bfn in this.bodyInfo) {
        if (bfn.indexOf('bmr') >= 0) {
          cuBmr = bodyFormData[bfn]
        }
      }
      // 根据选择的运动量和bmr计算推荐热量
      this[formApiObj].setFieldsValue({ calorie: parseInt(cuBmr * v) })
    },
    filterFoodTreeNode (v, node) {
      // console.log(v, node)
      // tree-select搜索过滤
      if (v !== undefined && v !== null && node.data !== undefined && node.data.props !== undefined && node.data.props.title !== undefined && node.data.props.title !== null && node.data.props.title.indexOf(v) >= 0) {
        return true
      }
      return false
    },
    handleNutritionSubmit (e) {
      e.preventDefault()
      this.nutritionForm.validateFieldsAndScroll((err, cuNurData) => {
        if (!err) {
          // console.log(this.nutritionForm.getFieldsValue(), this.nutritionSportForm.getFieldsValue())
          if (this.recordOri.id === undefined || this.recordOri.id < 1) {
            this.spinning = false
            this.$message.error('请先确认保存客户主诉信息！')
          } else {
            this.clickNutritionSave()
          }
        } else {
          this.spinning = false
          this.$message.error('请确认营养方案必填项填写完成！')
        }
      })
    },
    interventionMethodChange (e, calorieCalcMark = true) {
      this.interventionMethodHide = { supm: true, dpi: true }
      let setValObj = { supplement: null, dietary_pattern_id: undefined }
      let setValObjByNut = { supplement: null, dietary_pattern_id: undefined }
      if (e.indexOf('1') >= 0) {
        this.interventionMethodHide.dpi = false
        setValObj.dietary_pattern_id = this.dietaryInfo.dietary_pattern_id !== undefined && this.dietaryInfo.dietary_pattern_id !== null && this.dietaryInfo.dietary_pattern_id !== '' ? this.dietaryInfo.dietary_pattern_id : undefined
        setValObjByNut.dietary_pattern_id = this.nutritionOri.dietary_pattern_id !== undefined && this.nutritionOri.dietary_pattern_id !== null && this.nutritionOri.dietary_pattern_id !== '' ? this.nutritionOri.dietary_pattern_id : undefined
      }
      if (e.indexOf('2') >= 0) {
        this.interventionMethodHide.supm = false
        setValObj.supplement = this.dietaryInfo.supplement !== undefined ? this.dietaryInfo.supplement : null
        setValObjByNut.supplement = this.nutritionOri.supplement !== undefined ? this.nutritionOri.supplement : null
      }
      if (this.nutritionOri.id !== undefined && this.nutritionOri.id > 0) {
        // 已有数据则按原数据回显
        this.nutritionForm.setFieldsValue(setValObjByNut)
      } else {
        // 如果是新增则需要回显档案中的数据
        if (this.dietaryInfo.id !== undefined && this.dietaryInfo.id > 0) {
          this.nutritionForm.setFieldsValue(setValObj)
        }
      }
      if (calorieCalcMark) {
        this.$nextTick(() => {
          // 触发一下计算热量的
          this.adviseCalorieCalcChange()
        })
      }
    },
    adviseCalorieCalcChange (e) {
      // 根据档案中的预估热量、饮食模式推荐的值计算处方中建议的热量
      const cuNutFmData = this.nutritionForm.getFieldsValue()
      let cuCalorieVal = 2200
      // 有处方数据按处方中原来的值回显
      if (this.nutritionOri.id !== undefined && this.nutritionOri.id > 0) {
        cuCalorieVal = this.nutritionOri.calorie
      } else {
        // 没有处方的时候按档案的预估值回显
        if (this.dietaryInfo.id !== undefined && this.dietaryInfo.id > 0) {
          cuCalorieVal = this.dietaryInfo.calorie
        }
      }
      cuCalorieVal = cuCalorieVal === null || cuCalorieVal === '' ? 2200 : parseInt(cuCalorieVal)
      // console.log(cuNutFmData, e)
      const dpi = e !== undefined ? e : cuNutFmData.dietary_pattern_id

      if (cuNutFmData.intervention_method !== undefined && cuNutFmData.intervention_method.indexOf('1') >= 0 && dpi !== undefined && dpi !== null && dpi.indexOf(1003) >= 0) {
        // CRD饮食，‌即限能量平衡饮食 当前热量 - 500 若大于1500则置为1500
        cuCalorieVal -= 500
        cuCalorieVal = cuCalorieVal > 1500 ? 1500 : cuCalorieVal
      }
      this.nutritionForm.setFieldsValue({ calorie: cuCalorieVal })
    },
    clickshowPattern () {
      const cuPattern = this.nutritionForm.getFieldValue('dietary_pattern_id')
      if (cuPattern === undefined || cuPattern < 0) {
        this.$message.error('请先选择左侧饮食模式推荐！')
        return false
      }
      this.dietaryPatternParams = { pattern: cuPattern }
      this.dietaryPatternDrawerVisible = true
    },
    dietaryPatternDrawerVisibleChange (v) {
      this.dietaryPatternDrawerVisible = v
    },
    cpfChange (v, field) {
      // 另外两个字段同步自动计算
      if (this.cpfOri[field] === undefined) {
        return false
      }
      this.cpfMarks[field] = { [v]: v + '%' }
      // 用差值先计算小值，再用100%计算剩下的值
      const diff = v - this.cpfOri[field]
      // 当前字段增加变大，另外两个字段就这个差值的平均后减小
      // const diff1 = Math.ceil((v - this.cpfOri[field]) / 2)
      // const diff2 = Math.floor((v - this.cpfOri[field]) / 2)
      // const diffMin = Math.abs(diff1) <= Math.abs(diff2) ? diff1 : diff2
      // const diffMax = Math.abs(diff1) >= Math.abs(diff2) ? diff1 : diff2
      const otherCpfArr = JSON.parse(JSON.stringify(this.cpfOri))
      delete otherCpfArr[field]
      const valArr = Object.values(otherCpfArr)
      const keyArr = Object.keys(otherCpfArr)
      // 比较剩余哪个字段的默认值大，对应减小diffMax
      // 第一个大为默认
      // let key0Val = valArr[0] - diffMax
      // let key1Val = valArr[1] - diffMin
      let key0Val = 1
      let key1Val = 1
      // 按剩余2个字段的百分比计算小值
      if (valArr[0] <= valArr[1]) {
        const minNum = Math.floor(valArr[0] / (valArr[0] + valArr[1]) * diff)
        key0Val = valArr[0] - minNum
        key1Val = 100 - v - key0Val
      } else {
        const minNum = Math.floor(valArr[1] / (valArr[0] + valArr[1]) * diff)
        key1Val = valArr[1] - minNum
        key0Val = 100 - v - key1Val
      }
      key0Val = key0Val < 0 ? 0 : key0Val
      key0Val = key0Val > 100 ? 100 : key0Val
      key1Val = key1Val < 0 ? 0 : key1Val
      key1Val = key1Val > 100 ? 100 : key1Val

      this.cpfMarks[keyArr[0]] = { [key0Val]: key0Val + '%' }
      this.cpfMarks[keyArr[1]] = { [key1Val]: key1Val + '%' }
      // console.log({ [keyArr[0]]: key0Val, [keyArr[1]]: key1Val, [field]: v })

      this.nutritionForm.setFieldsValue({ [keyArr[0]]: key0Val, [keyArr[1]]: key1Val, [field]: v })
    },
    clickFoodMenu () {
      // 跳转食谱定制页面
      const cuPm = { patient: this.params.patient, type: 10 }
      if (this.recordOri.id !== undefined && this.recordOri.id > 0) {
        cuPm.recordOri = this.recordOri.id
      }
      if (this.recordOri.fm !== undefined && this.recordOri.fm > 0) {
        cuPm.fm = this.recordOri.fm
      }
      sessionStorage.setItem('HealthPatientFoodMenus.setPatientFoodMenu', JSON.stringify(cuPm))
      this.$router.push({ path: '/HealthPatientFoodMenus/setPatientFoodMenu' })
    },
    clickNutritionSave () {
      this.spinning = true
      this.tip = '处理中...'
      const sameUserArr = { service_record_id: this.recordOri.id, patient_id: this.params.patient, isSame: this.nutririonSameCheck }
      if (this.dietaryInfo.id !== undefined && this.dietaryInfo.id > 0) {
        sameUserArr.dietary_id = this.dietaryInfo.id
      }
      // 过敏食物那几个字段[还是的再传过去存一次，避免直接填处方就保存造成处方表没有这几个食物数据]
      const cuAptData = this.consultationForm.getFieldsValue()
      sameUserArr.favorite_diet_type = cuAptData.favorite_diet_type
      sameUserArr.favorite_diet_type_remarks = cuAptData.favorite_diet_type_remarks
      sameUserArr.food_favorite = cuAptData.food_favorite
      sameUserArr.food_dislike = cuAptData.food_dislike
      sameUserArr.allergies = cuAptData.allergies
      sameUserArr.food_intolerant = cuAptData.food_intolerant
      // sameUserArr.allergies_remarks = cuAptData.allergies_remarks
      // sameUserArr.food_intolerant_remarks = cuAptData.food_intolerant_remarks
      sameUserArr.nutrition_deficiency_remarks = cuAptData.nutrition_deficiency_remarks
      sameUserArr.water_intake = cuAptData.water_intake
      sameUserArr.activity_level = cuAptData.activity_level
      sameUserArr.dietary_calorie = cuAptData.calorie
      const cuNurData = this.nutritionForm.getFieldsValue()
      const cuNurSportData = this.nutritionSportForm.getFieldsValue()
      // console.log(Object.assign({}, sameUserArr, cuNurData), cuAptData, cuNurData)

      savePatientNutritionInfo(Object.assign({}, sameUserArr, cuNurData, cuNurSportData)).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('保存成功！')
          this.loadDietaryInfo()
          this.loadOriNutritionInfo(true)
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    clickAddSport () {
      const ckeys = this.nutritionSportForm.getFieldValue('sportKeys')
      const nextKeys = ckeys.concat(this.sportForNum++)
      this.nutritionSportForm.setFieldsValue({
        sportKeys: nextKeys,
      })
    },
    clickRemoveSport (idx) {
      const ckeys = this.nutritionSportForm.getFieldValue('sportKeys')
      const cuSportIds = this.nutritionSportForm.getFieldValue('sportId')
      if (ckeys.length === 1) {
        this.$message.error('不可删除！')
        return
      }
      if (ckeys[idx] === undefined) {
        this.$message.error('请正确选择要删除的运动计划！')
        return false
      }
      this.spinning = true
      if (cuSportIds[idx] !== undefined && cuSportIds[idx] > 0) {
        removePatientPrescriptionSport({ id: cuSportIds[idx], service_record: this.recordOri.id, patient: this.params.patient, enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
          if (res.status !== undefined && res.status === 1) {
            this.rmSportFormField(idx, ckeys)
          } else {
            this.$message.error(res.msg || '操作失败！请刷新后重试！')
            this.spinning = false
          }
        })
      } else {
        this.rmSportFormField(idx, ckeys)
      }
    },
    rmSportFormField (idx, ckeys) {
      this.nutritionSportForm.setFieldsValue({
        sportKeys: ckeys.filter((sv, key) => key !== idx),
      })
      this.$message.success('删除成功！')
      this.spinning = false
    },
    clickSurveyList () {
      if (this.recordOri.id === undefined || this.recordOri.id < 1) {
        // this.spinning = false
        this.$message.error('请先确认保存客户主诉信息！')
        return false
      } else {
        // 打开问卷抽屉
        this.$refs.surveyListDrawer.showDrawer({
          name: this.params.name, phone: this.params.phone, patient: this.params.patient, record: this.recordOri.id, surveyHasIds: this.surveyHasIds, patient_tags: this.patientInfo.his_patient_tag.length > 0 ? this.patientInfo.his_patient_tag : []
        })
      }
    },
    surveyReload () {
      // 问卷表单完成重新加载已填问卷列表数据
      this.loadSurveyResponseList(true)
    },
    loadSurveyResponseList (spMark = false) {
      if (spMark) {
        this.spinning = true
      }
      this.surveyHasIds = []
      listSurveyResponse({ service: this.recordOri.id, patient: this.params.patient, page: 1, pageSize: 1000 }).then((res) => {
        if (res.status !== undefined && res.status === 1 && res.data !== undefined) {
          this.surveyListData = res.data.map(sv => {
            this.surveyHasIds.push(sv.survey_id)
            return sv
          })
        }
        if (spMark) {
          this.spinning = false
        }
      })
    },
    clickEditSruveyRes (record) {
      // console.log(record)
      this.$refs.surveyInputDrawer.showDrawer({ name: record.contact_name, phone: record.contact_phone, patient: this.params.patient, record: this.recordOri.id, survey: record.survey_id, response: record.id, responseItems: record.misc_survey_response_item })
    },
    clickViewSruveyRes (record) {
      this.$refs.surveyResponseViewDrawer.showDrawer({ id: record.id })
    },
    clickDelSruveyRes (record) {
      this.spinning = true
      surveyResponseDelete({ id: record.id, service_record_id: record.service_record_id, patient_id: record.patient_id }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('删除成功！')
          this.surveyReload()
        } else {
          this.$message.error(res.msg || '操作失败！请刷新后重试！')
          this.spinning = false
        }
      })
    },
    handleFollowupSubmit (e) {
      e.preventDefault()
      this.followupForm.validateFieldsAndScroll((err, cuNurData) => {
        if (!err) {
          // console.log(this.followupForm.getFieldsValue())
          if (this.recordOri.id === undefined || this.recordOri.id < 1) {
            this.$message.error('请先确认保存客户主诉信息！')
            return false
          } else {
            this.clickFollowSave()
          }
        } else {
          this.spinning = false
          this.$message.error('请确认随访计划必填项填写完成！')
        }
      })
    },
    clickFollowSave () {
      this.spinning = true
      this.tip = '处理中...'
      const cuFolData = this.followupForm.getFieldsValue()
      savePatientFollowPlanInfo(Object.assign({ service_record_id: this.recordOri.id, patient_id: this.params.patient }, cuFolData)).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('保存成功！')
          Object.assign(this.followupOri, cuFolData)
          this.followupForm.setFieldsValue({ id: res.data.id })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    handleMonitorSubmit (e) {
      e.preventDefault()
      this.monitorForm.validateFieldsAndScroll((err, cuNurData) => {
        if (!err) {
          if (this.recordOri.id === undefined || this.recordOri.id < 1) {
            this.$message.error('请先确认保存客户主诉信息！')
            return false
          } else {
            this.clickMonitorSave()
          }
        } else {
          this.spinning = false
          this.$message.error('请确认指标检测计划必填项填写完成！')
        }
      })
    },
    clickMonitorSave () {
      this.spinning = true
      this.tip = '处理中...'
      const cuMonData = this.monitorForm.getFieldsValue()
      savePatientMonitorRecordInfo(Object.assign({ service_record_id: this.recordOri.id, patient_id: this.params.patient }, cuMonData)).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('保存成功！')
          Object.assign(this.monitorOri, cuMonData)
          this.monitorForm.setFieldsValue({ id: res.data.id })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    productPackageChange (v, op) {
      if (op.data !== undefined && op.data.props !== undefined && op.data.props.service_page_tags !== undefined) {
        this.cuProductPackageTags = op.data.props.service_page_tags !== null && op.data.props.service_page_tags !== '' ? op.data.props.service_page_tags.split(',') : []
        // 重置处方表单 运动方案需要重置才行
        this.nutritionSportForm.resetFields(['duration', 'example', 'frequency', 'plan_content', 'sportId', 'sportKeys', 'sport_type'])
        if (this.cuProductPackageTags.indexOf('3') >= 0) {
          this.sportForNum = 0
          this.clickAddSport()
          // 前面重置后需要重新赋值
          this.resetNutritionSportFormData()
        }
        // 处理推荐的饮食模式
        if (op.data.props.dietary_pattern_ids !== undefined && op.data.props.dietary_pattern_ids.length > 0) {
          let oknum = 0
          this.dietaryPatternSelectArr = this.dietaryPatternSelectArr.map(dpv => {
            dpv.sort = Math.abs(dpv.sort)
            if (op.data.props.dietary_pattern_ids.indexOf(dpv.value) >= 0) {
              dpv.rec = true
              dpv.sort = -dpv.sort
            } else {
              dpv.rec = false
            }
            oknum++
            return dpv
          })
          const that = this
          const timer = setInterval(() => {
            if (oknum >= that.dietaryPatternSelectArr.length) {
              clearInterval(timer)
              that.dietaryPatternSelectArr.sort((a, b) => {
                return (a.sort >= 0 || b.sort >= 0) ? a.sort - b.sort : b.sort - a.sort
              })
              that.dietaryPatternSelectArr = [...that.dietaryPatternSelectArr]
              //     console.log(that.dietaryPatternSelectArr)
            }
          }, 100)
        }
      } else {
        this.cuProductPackageTags = false
      }
    },
    tabChange (step, tabTmp = false) {
      tabTmp = tabTmp === false ? parseInt(this.activeTabKey) : parseInt(tabTmp)
      let nextTab = tabTmp + parseInt(step)
      nextTab = nextTab > 6 ? 6 : nextTab
      nextTab = nextTab < 1 ? 1 : nextTab
      nextTab = nextTab.toString()

      if (this.cuProductPackageTags === false || nextTab <= 3 || this.cuProductPackageTags.indexOf(nextTab) >= 0) {
        this.activeTabKey = nextTab
        if (this.activeTabKey > 1) {
          this.showNotification()
        } else {
          this.hideNotification()
        }
      } else {
        // 不可选的状态的则继续往下一级判断，直到nextTab为6依然是不可选的则提示没有更多操作
        if (nextTab >= 6) {
          this.$message.error('已到最后啦~没有更多操作了！')
          return false
        } else {
          this.tabChange(step, nextTab)
        }
      }
    },
    hideNotification () {
      // 隐藏
      this.$notification.close(this.showNotificationKey)
      this.showNotificationMark = true
    },
    showNotification () {
      // 展示
      const aptfm = this.appointForm.getFieldsValue()
      const cslfm = this.consultationForm.getFieldsValue()
      let goalText = (aptfm.goal !== undefined && aptfm.goal !== null && aptfm.goal !== '') ? this.$options.filters.showAllowsText(aptfm.goal, this.goalSelectArr, '、') : []
      let diseaseText = (cslfm.disease !== undefined && cslfm.disease !== null && cslfm.disease !== '') ? this.$options.filters.showAllowsText(cslfm.disease, this.diseaseSelectArr, '、') : []
      let favoriteDdietText = (cslfm.favorite_diet_type !== undefined && cslfm.favorite_diet_type !== null && cslfm.favorite_diet_type !== '') ? this.$options.filters.showAllowsText(cslfm.favorite_diet_type, this.favoriteDietTypeSelectArr, '、') : []
      let ffavoriteText = (cslfm.food_favorite !== undefined && cslfm.food_favorite !== null && cslfm.food_favorite !== '') ? this.$options.filters.showAllowsText(cslfm.food_favorite, this.foodTreeData, '、', 'value', 'title') : []
      let fdislikeText = (cslfm.food_dislike !== undefined && cslfm.food_dislike !== null && cslfm.food_dislike !== '') ? this.$options.filters.showAllowsText(cslfm.food_dislike, this.foodTreeData, '、', 'value', 'title') : []
      let fallergiesText = (cslfm.allergies !== undefined && cslfm.allergies !== null && cslfm.allergies !== '') ? this.$options.filters.showAllowsText(cslfm.allergies, this.foodTreeData, '、', 'value', 'title') : []
      let fintolerantText = (cslfm.food_intolerant !== undefined && cslfm.food_intolerant !== null && cslfm.food_intolerant !== '') ? this.$options.filters.showAllowsText(cslfm.food_intolerant, this.foodTreeData, '、', 'value', 'title') : []
      // if (aptfm.goal !== undefined && aptfm.goal !== null && aptfm.goal !== '') {
      //   const goalTextArr = []
      //   this.goalSelectArr.map(gl => {
      //     if (aptfm.goal.includes(gl.value)) {
      //       goalTextArr.push(gl.label)
      //     }
      //   })
      //   goalText = goalTextArr.join('、')
      // }
      // console.log(cslfm)

      this.$notification.open({
        message: '客户主述信息',
        placement: 'topLeft',
        top: '5px',
        duration: null,//不自动关闭
        key: this.showNotificationKey,
        getContainer: () => document.getElementById('layout-dashboard'),
        description: h => {
          const brhtml = h('br')
          const aptText = [
            '预约原因：' + this.$options.filters.showTextNotNull(aptfm.reason),
            brhtml,
            '客户期望达到效果：' + this.$options.filters.showTextNotNull(aptfm.expectation),
            brhtml,
            '营养师管理目标：' + this.$options.filters.showTextNotNull(goalText),
            brhtml,
            '目标备注：' + this.$options.filters.showTextNotNull(aptfm.goal_remarks)
          ]
          let bodyText = []
          this.bodyInfo.map((byv, byi) => {
            let itemText = byv.item_name + '：' + byv.result_val + byv.unit_name
            const culen = byi + 1
            itemText = (byi > 0) ? '，' + itemText : itemText
            // itemText = (byi > 0 && culen % 2 === 0) ? '，' + itemText : itemText
            bodyText.push(itemText)
            // if (culen % 2 === 0 && this.bodyInfo[culen] !== undefined) { // 2个换行
            //   bodyText.push(brhtml)
            // }
          })
          if (bodyText.length > 0) {
            aptText.push(h('a-divider', { style: { margin: '5px 0' } }))
            aptText.push(h('p', { style: { marginBottom: '8px', color: 'rgba(0, 0, 0, 0.85)', fontSize: '16px' } }, '客户最新体格指标'))
          }
          const cslText = [
            h('a-divider', { style: { margin: '5px 0' } }),
            h('p', { style: { marginBottom: '8px', color: 'rgba(0, 0, 0, 0.85)', fontSize: '16px' } }, '病史及用药信息'),
            '疾病信息：' + this.$options.filters.showTextNotNull(diseaseText),
            brhtml,
            '疾病信息备注：' + this.$options.filters.showTextNotNull(cslfm.disease_remarks),
            brhtml,
            '服药及营养补充剂：' + this.$options.filters.showTextNotNull(cslfm.medication),
            brhtml,
            '家族病史：' + this.$options.filters.showTextNotNull(cslfm.family_history),
            h('a-divider', { style: { margin: '5px 0' } }),
            h('p', { style: { marginBottom: '8px', color: 'rgba(0, 0, 0, 0.85)', fontSize: '16px' } }, '饮食习惯'),
            '喜好饮食类型：' + this.$options.filters.showTextNotNull(favoriteDdietText),
            brhtml,
            '喜好饮食类型备注：' + this.$options.filters.showTextNotNull(cslfm.favorite_diet_type_remarks),
            brhtml,
            '喜欢的食物：' + this.$options.filters.showTextNotNull(ffavoriteText),
            brhtml,
            '不喜欢的食物：' + this.$options.filters.showTextNotNull(fdislikeText),
            brhtml,
            '过敏食物：' + this.$options.filters.showTextNotNull(fallergiesText),
            brhtml,
            '不耐受及敏感食物：' + this.$options.filters.showTextNotNull(fintolerantText),
            brhtml,
            '缺乏营养：' + this.$options.filters.showTextNotNull(cslfm.nutrition_deficiency_remarks),
          ]
          return h('div', {}, aptText.concat(bodyText, cslText))
        },
        style: {
          width: '280px',
          padding: '15px'
        },
        onClose: () => {
          this.showNotificationMark = true
        },
      })
      this.showNotificationMark = false
    },
  },
  beforeDestroy () {
    this.hideNotification()
  }
}

</script>

<style lang="scss" scoped>
#formRow {
  // 左侧锚点的样式
  /deep/ .ant-anchor-wrapper {
    margin-left: 0;
    padding-left: 0;
    border-radius: 12px;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  }
  /deep/ .ant-anchor-ink::before {
    width: 0;
  }
  // 左侧表单中item的提示内容收缩窄点
  /deep/ .ant-form-explain {
    min-height: unset;
    line-height: 1;
  }
  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
  // form为small的时候其他组件的缩进
  textarea[size="small"] {
    padding: 1px 7px;
  }
  /deep/
    .ant-select-sm
    .ant-select-selection--multiple
    .ant-select-selection__placeholder {
    margin-left: 2px !important;
  }
  /deep/
    .ant-select-sm
    .ant-select-selection--multiple
    .ant-select-selection__clear,
  /deep/ .ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
    top: 17px;
  }
  /deep/ .ant-select-selection--multiple > ul > li,
  /deep/
    .ant-select-selection--multiple
    .ant-select-selection__rendered
    > ul
    > li {
    margin-top: 4px;
  }
  .litle-title {
    font-weight: bold;
    color: black;
  }
}
.label-color {
  color: #8c8c8c;
}
.content-color {
  color: black;
}
.must-mark {
  color: #f5222d;
  font-family: SimSun, sans-serif;
}
</style>