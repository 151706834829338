import { render, staticRenderFns } from "./SetServiceRecord.20241202form-create最终版.vue?vue&type=template&id=b8440764&scoped=true"
import script from "./SetServiceRecord.20241202form-create最终版.vue?vue&type=script&lang=js"
export * from "./SetServiceRecord.20241202form-create最终版.vue?vue&type=script&lang=js"
import style0 from "./SetServiceRecord.20241202form-create最终版.vue?vue&type=style&index=0&id=b8440764&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8440764",
  null
  
)

export default component.exports