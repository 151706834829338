<template>
  <a-spin
    tip="处理中..."
    size="large"
    :spinning="spinning"
  >
    <a-descriptions
      :column="5"
      bordered
    >
      <a-descriptions-item
        :span="3"
        label="食物名称"
      >
        {{foodDetail.name}}
      </a-descriptions-item>
      <a-descriptions-item
        :span="2"
        label="分类"
      >
        {{foodDetail.category_name}}
      </a-descriptions-item>

      <a-descriptions-item :label="'热量' + (foodDetail.calory_unit === undefined ? '' : '('+foodDetail.calory_unit+')' ) ">
        {{foodDetail.calory}}
      </a-descriptions-item>
      <a-descriptions-item :label="'热量' + (foodDetail.joule_unit === undefined ? '' : '('+foodDetail.joule_unit+')' ) ">
        {{foodDetail.joule}}
      </a-descriptions-item>
      <a-descriptions-item :label="'蛋白质' + (foodDetail.protein_unit === undefined ? '' : '('+foodDetail.protein_unit+')' ) ">
        {{foodDetail.protein}}
      </a-descriptions-item>
      <a-descriptions-item :label="'脂肪' + (foodDetail.fat_unit === undefined ? '' : '('+foodDetail.fat_unit+')' ) ">
        {{foodDetail.fat}}
      </a-descriptions-item>
      <a-descriptions-item :label="'碳水化合物' + (foodDetail.carbohydrate_unit === undefined ? '' : '('+foodDetail.carbohydrate_unit+')' ) ">
        {{foodDetail.carbohydrate}}
      </a-descriptions-item>

      <a-descriptions-item :label="'膳食纤维' + (foodDetail.fiber_dietary_unit === undefined ? '' : '('+foodDetail.fiber_dietary_unit+')' ) ">
        {{foodDetail.fiber_dietary}}
      </a-descriptions-item>
      <a-descriptions-item :label="'糖' + (foodDetail.sugar_unit === undefined ? '' : '('+foodDetail.sugar_unit+')' ) ">
        {{foodDetail.sugar}}
      </a-descriptions-item>
      <a-descriptions-item :label="'饱和脂肪' + (foodDetail.saturated_fat_unit === undefined ? '' : '('+foodDetail.saturated_fat_unit+')' ) ">
        {{foodDetail.saturated_fat}}
      </a-descriptions-item>
      <a-descriptions-item :label="'反式脂肪' + (foodDetail.fatty_acid_unit === undefined ? '' : '('+foodDetail.fatty_acid_unit+')' ) ">
        {{foodDetail.fatty_acid}}
      </a-descriptions-item>
      <a-descriptions-item :label="'单不饱和脂肪' + (foodDetail.mufa_unit === undefined ? '' : '('+foodDetail.mufa_unit+')' ) ">
        {{foodDetail.mufa}}
      </a-descriptions-item>

      <a-descriptions-item :label="'多不饱和脂肪' + (foodDetail.pufa_unit === undefined ? '' : '('+foodDetail.pufa_unit+')' ) ">
        {{foodDetail.pufa}}
      </a-descriptions-item>
      <a-descriptions-item :label="'胆固醇' + (foodDetail.cholesterol_unit === undefined ? '' : '('+foodDetail.cholesterol_unit+')' ) ">
        {{foodDetail.cholesterol}}
      </a-descriptions-item>
      <a-descriptions-item :label="'酒精' + (foodDetail.alcohol_unit === undefined ? '' : '('+foodDetail.alcohol_unit+')' ) ">
        {{foodDetail.alcohol}}
      </a-descriptions-item>
      <a-descriptions-item :label="'维生素A' + (foodDetail.vitamin_a_unit === undefined ? '' : '('+foodDetail.vitamin_a_unit+')' ) ">
        {{foodDetail.vitamin_a}}
      </a-descriptions-item>
      <a-descriptions-item :label="'胡萝卜素' + (foodDetail.carotene_unit === undefined ? '' : '('+foodDetail.carotene_unit+')' ) ">
        {{foodDetail.carotene}}
      </a-descriptions-item>

      <a-descriptions-item :label="'维生素B1' + (foodDetail.thiamine_unit === undefined ? '' : '('+foodDetail.thiamine_unit+')' ) ">
        {{foodDetail.thiamine}}
      </a-descriptions-item>
      <a-descriptions-item :label="'维生素B2' + (foodDetail.lactoflavin_unit === undefined ? '' : '('+foodDetail.lactoflavin_unit+')' ) ">
        {{foodDetail.lactoflavin}}
      </a-descriptions-item>
      <a-descriptions-item :label="'维生素B6' + (foodDetail.vitamin_b6_unit === undefined ? '' : '('+foodDetail.vitamin_b6_unit+')' ) ">
        {{foodDetail.vitamin_b6}}
      </a-descriptions-item>
      <a-descriptions-item :label="'维生素B12' + (foodDetail.vitamin_b12_unit === undefined ? '' : '('+foodDetail.vitamin_b12_unit+')' ) ">
        {{foodDetail.vitamin_b12}}
      </a-descriptions-item>
      <a-descriptions-item :label="'维生素C' + (foodDetail.vitamin_c_unit === undefined ? '' : '('+foodDetail.vitamin_c_unit+')' ) ">
        {{foodDetail.vitamin_c}}
      </a-descriptions-item>

      <a-descriptions-item :label="'维生素D' + (foodDetail.vitamin_d_unit === undefined ? '' : '('+foodDetail.vitamin_d_unit+')' ) ">
        {{foodDetail.vitamin_d}}
      </a-descriptions-item>
      <a-descriptions-item :label="'维生素E' + (foodDetail.vitamin_e_unit === undefined ? '' : '('+foodDetail.vitamin_e_unit+')' ) ">
        {{foodDetail.vitamin_e}}
      </a-descriptions-item>
      <a-descriptions-item :label="'维生素K' + (foodDetail.vitamin_k_unit === undefined ? '' : '('+foodDetail.vitamin_k_unit+')' ) ">
        {{foodDetail.vitamin_k}}
      </a-descriptions-item>
      <a-descriptions-item :label="'烟酸' + (foodDetail.niacin_unit === undefined ? '' : '('+foodDetail.niacin_unit+')' ) ">
        {{foodDetail.niacin}}
      </a-descriptions-item>
      <a-descriptions-item :label="'叶酸' + (foodDetail.folacin_unit === undefined ? '' : '('+foodDetail.folacin_unit+')' ) ">
        {{foodDetail.folacin}}
      </a-descriptions-item>

      <a-descriptions-item :label="'泛酸' + (foodDetail.pantothenic_unit === undefined ? '' : '('+foodDetail.pantothenic_unit+')' ) ">
        {{foodDetail.pantothenic}}
      </a-descriptions-item>
      <a-descriptions-item :label="'生物素' + (foodDetail.biotin_unit === undefined ? '' : '('+foodDetail.biotin_unit+')' ) ">
        {{foodDetail.biotin}}
      </a-descriptions-item>
      <a-descriptions-item :label="'胆碱' + (foodDetail.choline_unit === undefined ? '' : '('+foodDetail.choline_unit+')' ) ">
        {{foodDetail.choline}}
      </a-descriptions-item>
      <a-descriptions-item :label="'钠' + (foodDetail.natrium_unit === undefined ? '' : '('+foodDetail.natrium_unit+')' ) ">
        {{foodDetail.natrium}}
      </a-descriptions-item>
      <a-descriptions-item :label="'磷' + (foodDetail.phosphor_unit === undefined ? '' : '('+foodDetail.phosphor_unit+')' ) ">
        {{foodDetail.phosphor}}
      </a-descriptions-item>

      <a-descriptions-item :label="'钾' + (foodDetail.kalium_unit === undefined ? '' : '('+foodDetail.kalium_unit+')' ) ">
        {{foodDetail.kalium}}
      </a-descriptions-item>
      <a-descriptions-item :label="'镁' + (foodDetail.magnesium_unit === undefined ? '' : '('+foodDetail.magnesium_unit+')' ) ">
        {{foodDetail.magnesium}}
      </a-descriptions-item>
      <a-descriptions-item :label="'钙' + (foodDetail.calcium_unit === undefined ? '' : '('+foodDetail.calcium_unit+')' ) ">
        {{foodDetail.calcium}}
      </a-descriptions-item>
      <a-descriptions-item :label="'铁' + (foodDetail.iron_unit === undefined ? '' : '('+foodDetail.iron_unit+')' ) ">
        {{foodDetail.iron}}
      </a-descriptions-item>
      <a-descriptions-item :label="'锌' + (foodDetail.zinc_unit === undefined ? '' : '('+foodDetail.zinc_unit+')' ) ">
        {{foodDetail.zinc}}
      </a-descriptions-item>

      <a-descriptions-item :label="'碘' + (foodDetail.iodine_unit === undefined ? '' : '('+foodDetail.iodine_unit+')' ) ">
        {{foodDetail.iodine}}
      </a-descriptions-item>
      <a-descriptions-item :label="'硒' + (foodDetail.selenium_unit === undefined ? '' : '('+foodDetail.selenium_unit+')' ) ">
        {{foodDetail.selenium}}
      </a-descriptions-item>
      <a-descriptions-item :label="'铜' + (foodDetail.copper_unit === undefined ? '' : '('+foodDetail.copper_unit+')' ) ">
        {{foodDetail.copper}}
      </a-descriptions-item>
      <a-descriptions-item :label="'氟' + (foodDetail.fluorine_unit === undefined ? '' : '('+foodDetail.fluorine_unit+')' ) ">
        {{foodDetail.fluorine}}
      </a-descriptions-item>
      <a-descriptions-item :label="'锰' + (foodDetail.manganese_unit === undefined ? '' : '('+foodDetail.manganese_unit+')' ) ">
        {{foodDetail.manganese}}
      </a-descriptions-item>

      <a-descriptions-item label="健康等级">
        {{'food.health_light' | showText(foodDetail.health_light)}}
      </a-descriptions-item>
      <a-descriptions-item
        :span="2"
        label="GI"
      >
        {{foodDetail.gi}}
      </a-descriptions-item>
      <a-descriptions-item
        :span="2"
        label="GL"
      >
        {{foodDetail.gl}}
      </a-descriptions-item>

    </a-descriptions>
  </a-spin>
</template>

<script>
import { viewFoodCalory } from '@/api/dms/foodCalory'

export default {
  name: 'FoodCaloryView',
  data () {
    return {
      spinning: false,
      modalVisible: false,
      foodDetail: {}
    }
  },
  props: {
    foodCaloryId: {
      type: Number,
      default: null
    },
  },
  filters: {
    statusTypeFilter (type) {
      if (type <= 0) {
        return 'error'
      } else if (type === 10) {
        return 'success'
      } else {
        return 'default'
      }
    }
  },
  created () {
    this.spinning = true
    console.log(this.foodCaloryId)
    viewFoodCalory({ enterprise: this.$store.getters.userInfo.user.target_enterprise, user: this.$store.getters.userInfo.user.id, food: this.foodCaloryId, join: ['category'] }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        console.log(res)
        let gInfo = (res.data.glycemic_info !== undefined && res.data.glycemic_info !== null && res.data.glycemic_info !== '') ? JSON.parse(res.data.glycemic_info) : {}
        res.data.gi = gInfo.gi !== undefined && gInfo.gi.value !== '' ? gInfo.gi.value + '(' + gInfo.gi.label + ')' : ''
        res.data.gl = gInfo.gl !== undefined && gInfo.gl.value !== '' ? gInfo.gl.value + '(' + gInfo.gl.label + ')' : ''
        this.foodDetail = res.data
      }
      this.spinning = false
    })
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
