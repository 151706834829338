<template>
  <a-drawer
    width="300"
    placement="right"
    :maskClosable="false"
    :closable="true"
    :visible="followupRecordVisibleCu"
    title="随访记录"
    @close="closefollowupRecordDrawer"
  >
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="followupRecordSpinning"
    >
      <div>
        <form-create
          v-model="cformFupRecordApi"
          :rule="cformFupRecordRule"
          :option="cformOption"
        ></form-create>
      </div>

      <a-row>
        <a-col
          :offset="6"
          :span="15"
        >
          <a-button
            @click="closefollowupRecordDrawer()"
            class="mr-5"
          >关闭</a-button>
          <a-button
            @click="submitBtnClick"
            type="primary"
          >确认保存</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </a-drawer>
</template>

<script>
// import { savefollowupRecord } from '@/api/dms/serviceRecordRating'

export default ({
  name: 'FollowupRecord',
  data () {
    return {
      followupRecordVisibleCu: false,
      followupRecordSpinning: false,
      cformFupRecordApi: {},
      cformFupRecordRule: [
        {
          type: 'hidden',
          field: 'plan_id',
        },
        {
          type: 'hidden',
          field: 'patient_id',
        },
        {
          type: 'hidden',
          field: 'method',
        },
        {
          type: 'hidden',
          field: 'create_user',
          value: this.$store.getters.userInfo.user.id
        },
        {
          type: 'input',
          title: '随访内容：',
          field: 'comment_content',
          props: { type: 'textarea', autoSize: { minRows: 7 }, placeholder: '请填写随访内容' },
          validate: [
            { required: true, message: '请确认填写随访内容', whitespace: true }
          ]
        }
      ],
      cformOption: {
        // col: {
        //   span: 24
        // },
        // wrap: {
        //   labelCol: { span: 6 },
        //   wrapperCol: { span: 18 }
        // },
        form: {
          layout: 'vertical'
        },
        submitBtn: false
      }
    }
  },
  created () {

  },
  methods: {
    showDrawer (params) {
      this.followupRecordVisibleCu = true
      this.$nextTick(() => {
        this.cformFupRecordApi.setValue(params)
      })
    },
    closefollowupRecordDrawer () {
      this.followupRecordVisibleCu = false
    },
    submitBtnClick () {
      this.cformFupRecordApi.validate((valid2, fail2) => {
        if (valid2) {
          let cuFormData = this.cformFupRecordApi.formData()
          console.log(cuFormData)
          // this.followupRecordSpinning = true
          // savefollowupRecord(cuFormData).then((res) => {
          //   if (res.status !== undefined && res.status === 1) {
          //     this.cformFupRecordApi.resetFields()
          //     this.closefollowupRecordDrawer()
          //     this.$emit('complete')
          //     this.$message.success('保存成功！')
          //   } else {
          //     this.$message.error(res.msg || '保存失败！请刷新后重试！')
          //   }
          //   this.followupRecordSpinning = false
          // })
        }
      })
    }
  }
})

</script>

<style lang="scss" scoped>
</style>